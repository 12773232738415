'use client'

import Link, { type LinkProps } from 'next/link'
import { type ReactNode } from 'react'
import { useLogin } from '../account/context/use-login'

export const StyledBreadcrumbs = ({ children }: { children: ReactNode[] }) => {
  return (
    <ol className='flex overflow-hidden sm:max-w-[calc(100vw-300px)]'>
      {children}
    </ol>
  )
}

export const BreadCrumbDiv = ({
  children,
  className,
  ...props
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <li
      {...props}
      className={`list-none content-center align-middle text-sm leading-[18px] text-primary-contrast-text ${className}`}
    >
      {children}
    </li>
  )
}

export const StyledPath = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) => {
  return (
    <div
      className={`truncate text-sm font-bold text-primary-contrast-text ${className}`}
    >
      {children}
    </div>
  )
}

export const StyledLink = ({
  children,
  ...props
}: { children: ReactNode } & LinkProps) => {
  return (
    <Link
      prefetch={false}
      className='cursor-pointer truncate text-sm font-bold text-primary-contrast-text underline'
      {...props}
    >
      {children}
    </Link>
  )
}

const Heading = ({
  children,
  variant,
}: {
  children: ReactNode
  variant: 'h1' | 'h4'
}) => {
  const className = 'ml-1 text-base font-bold text-primary-contrast-text inline'
  if (variant === 'h1') {
    return <h1 className={className}>{children}</h1>
  }
  return <h4 className={className}>{children}</h4>
}

export const NoBreadCrumbs = () => {
  const { loggedIn, userAttributes, openLogin } = useLogin()
  const headingVariant = 'h4'

  if (loggedIn) {
    return (
      <BreadCrumbDiv data-testid='notification-div'>
        <span className='hidden md:block'>
          Welcome back {userAttributes?.given_name}!
        </span>
      </BreadCrumbDiv>
    )
  }

  return (
    <BreadCrumbDiv data-testid='notification-div' className='w-full'>
      <div className='flex items-center'>
        <div
          className='mr-1 cursor-pointer truncate text-sm font-bold text-primary-contrast-text underline'
          onClick={() => openLogin('splash')}
          data-testid='signup-link'
        >
          Sign up
        </div>{' '}
        to the new <Heading variant={headingVariant}>Lasoo</Heading>
      </div>
    </BreadCrumbDiv>
  )
}
